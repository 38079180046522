.title {
  color: #001e7f;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 47px;
  margin: 10px;

  @media screen and (max-width: 680px) {
    font-size: 18px;
  }
}

.item {
  font-size: 18px;
  margin-bottom: 15px;

  @media screen and (max-width: 680px) {
    font-size: 13px;
  }
}
