.label {
  height: 30px;
  width: 100%;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
  border-radius: 7px;
  background-color: #495464;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  @media screen and (min-width: 576px) {
    width: 370px;
  }
}
.scheduleContainer {
  @media screen and (min-width: 768px) {
    display: flex;
    align-items: center;
    gap: 15px;
  }
}
.schedule {
  color: #001e7f;
  font-family: Heebo;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  text-align: right;
}
.info {
  color: #495464;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 27px;
  text-align: right;
}

.multiTitle {
  color: #001e7f;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 47px;
  margin-bottom: 30px;
}

.steps {
  padding: 0;
  z-index: 1;
}

.stepItem {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 0;
  width: 100%;
}

.stepConnector {
  box-sizing: border-box;
  width: 2px;
  border-right: 2px solid #001e7f;
  position: relative;
  z-index: -1;
  top: 35px;
  right: 22px;
}

.pointConnector {
  box-sizing: border-box;
  width: 2px;
  border-right: 2px solid #001e7f;
  position: relative;
  z-index: -1;
  right: 16px;
  top: 26px;
}

.stepNumber {
  margin-left: 19px;
  background-color: #001e7f;
  color: #f3f5fa;
  min-width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
}

.pointNumber {
  background-color: #001E7F;
  min-width: 30px;
  height: 30px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 19px;
}

.stepInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.stepLabel {
  color: #001e7f;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 21px;
  margin: 0;
  @media screen and (max-width: 680px) {
    font-size: 14px;
  }
}

.extraMargin {
  margin-top: 10px;
}