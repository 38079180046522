.text {
  display: inline;
  width: 100%;
}

.readOrHide {
  color: rgb(192, 192, 192);
  cursor: pointer;
  margin: 0 10px;
}
