.stepper {
  position: relative;
}

.steps {
  padding: 0;
  z-index: 1;
}

.stepItem {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 0;
  width: 100%;
}

.stepConnector {
  box-sizing: border-box;
  width: 2px;
  border-right: 2px solid #001e7f;
  position: relative;
  z-index: -1;
  top: 43px;
  right: 22px;
}

.stepNumber {
  margin-left: 19px;
  background-color: #001e7f;
  color: #f3f5fa;
  min-width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
}

.stepInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.stepLabel {
  color: #001e7f;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 21px;
  margin: 0;
  @media screen and (max-width: 680px) {
    font-size: 14px;
  }
}

.stepDescription {
  color: #495464;
  font-size: 17px;
  letter-spacing: 0;
  line-height: 21px;
  margin: 0;
  @media screen and (max-width: 680px) {
    font-size: 13px;
  }
}

.stepDuration {
    color: #8a929f;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    margin: 0;
}

.singleStepHidden {
  margin-top: 48px;
  padding: 0;
  z-index: 1;
  overflow: hidden;
}
