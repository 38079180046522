// @import "./pages/HomePage/home-page.scss";
// @import "./pages/TourPage/tourPage.scss";
// @import "./pages/ProductGalleryPage/product-gallery.scss";
// @import "./components/PageCards/page-cards.scss";
// @import "./components/CitiesContent/cities_content.scss";
// @import "./components/HomePageForm/home-page-form.scss";
// @import "./components/HomePageForm/date-picker.scss";
// @import "./components/DestinationPageForm/destination-page-form.scss";
// @import "./components/Calendar/Calendar.scss";
// @import "./components/Gallery/gallery.scss";
// @import "components/ProductDetails/IconsGroup/iconsGroup.scss";
// @import "components/ProductDetails/Price/Price.scss";
// @import "components/ProductDetails/TourList/tourList.scss";
// @import "components/ProductDetails/TourSettings/tourSettings.scss";
// @import "components/ProductDetails/TourStepper/TourStepper.scss";

.App {
  box-sizing: border-box;
  font-family: 'Heebo', sans-serif;
}

.container {
  max-width: 1320px;
  display: flex;
  justify-content: center;
  margin: auto;
}