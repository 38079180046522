.productTitle {
    color: #001e7f;
    font-size: 32px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 47px;
    margin-bottom: 10px;
  
    @media screen and (max-width: 680px) {
      font-size: 18px;
    }
  }