.multiTitle {
  color: #001e7f;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 47px;
  margin-bottom: 30px;
}

.title {
  color: #001e7f;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  text-align: right;
  @media screen and (max-width: 680px) {
    font-size: 14px;
  }
}

.description {
  color: #495464;
  font-size: 18px;
  letter-spacing: 0;
  text-align: right;
  @media screen and (max-width: 680px) {
    font-size: 13px;
  }
}
