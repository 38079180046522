.item {
  list-style: none;
}
.list {
  padding-inline-start: 0;
}
.title {
  color: #001e7f;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0;
  text-align: right;
}
.name {
  color: #001e7f;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  text-align: right;
}
.description {
  color: #495464;
  font-size: 18px;
  letter-spacing: 0;
  text-align: right;
  @media screen and (max-width: 680px) {
    font-size: 13px;
  }
}

.expect {
  margin-top: 50px;
}
