.multiItem {
  margin-bottom: 30px;
}

.multiHeader {
  cursor: pointer;
}

.dayLabel {
  height: 30px;
  width: 90px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
  border-radius: 7px;
  background-color: #495464;
  display: flex;
  justify-content: center;
  align-items: center;
}

.multiTitle {
  color: #001e7f;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 47px;
  margin-bottom: 30px;
}

.multiItemContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.multiItemTitle {
  color: #001e7f;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 0;
  letter-spacing: 0;
  text-align: right;
  word-break: break-all;
}

.multiItemCount {
  color: #495464;
  font-size: 18px;
  letter-spacing: 0;
  text-align: right;
}

.nonActive {
  display: none;
}

.steps {
  padding: 0;
  z-index: 1;
}

.stepItem {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 0;
  width: 100%;
}

.stepConnector {
  box-sizing: border-box;
  width: 2px;
  border-right: 2px solid #001e7f;
  position: relative;
  z-index: -1;
  top: 35px;
  right: 22px;
}

.stepNumber {
  margin-left: 19px;
  background-color: #001e7f;
  color: #f3f5fa;
  min-width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
}

.stepInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.stepLabel {
  color: #001e7f;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 21px;
  margin: 0;
  @media screen and (max-width: 680px) {
    font-size: 14px;
  }
}

.stepDescription {
  color: #495464;
  font-size: 17px;
  letter-spacing: 0;
  line-height: 21px;
  margin: 0;
  @media screen and (max-width: 680px) {
    font-size: 13px;
  }
}

.stepDuration {
  color: #8a929f;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
  margin: 0;
}
