.lists {
  display: flex;
  gap: 139px;
  @media screen and (max-width: 680px) {
    flex-direction: column;
    gap: 0;
  }
}

.list {
  padding: 0;
  @media screen and (max-width: 680px) {
    margin: 0;
  }
}

.listItem {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.listItemAccept {
  list-style: none;
  color: #495464;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 27px;
  @media screen and (max-width: 680px) {
    font-size: 16px;
  }
}

.listItemDecline {
  list-style: none;
  color: #495464;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 27px;
  @media screen and (max-width: 680px) {
    font-size: 16px;
  }
}
