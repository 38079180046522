.productPage {
  margin: 30px 0;
  padding: 0 30px;
}
.productDetails {
  padding: 20px 20px 40px;
  border: 1px solid #f6f6f6;
  box-shadow: 1px 4px 7px rgb(0 0 0 / 20%);
}

.productTitle {
  color: #001e7f;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0;
  margin: 10px 0;
  @media screen and (max-width: 680px) {
    font-size: 18px;
  }
}
.productText {
  color: #495464;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 20px;
  margin: 0;

  @media screen and (max-width: 680px) {
    font-size: 13px;
  }
}

.notifications {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}