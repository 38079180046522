.showMoreContainer {
  display: flex;
  flex-direction: row;
}

.showMore {
  color: #001e7f;
  font-size: 18px;
  cursor: pointer;
  text-decoration: underline;
  font-family: Heebo, sans-serif;

  @media screen and (max-width: 500px) {
    font-size: 12px;
  }
}

.showMoreArrow {
  margin: 0 5px;
  color: #001e7f !important;
  visibility: visible;

  @media screen and (max-width: 500px) {
    visibility: hidden;
  }
}
